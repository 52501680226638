<script setup>
import { ref } from "vue";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { CountTo } from "vue3-count-to";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr";
import GenelBakis from "./genel-bakis";
import sonAktiviteler from "./sonAktiviteler";
import store from "@/state/store";
// import appConfig from "../../../app.config";
import i18n from "../../i18n";

const { t } = i18n.global;

const date = ref(null);

const user = ref(store.getters['userAuth/getUserData'])
/* eslint-disable */
const config = {
  mode: "range",
  locale: Turkish,
  maxDate: new Date(),
  defaultDate: [new Date().setDate(new Date().getDate() - 30), new Date()],
  onReady(dObj, dStr, fp, dayElem) {
    date.value = dStr;
  },
};
/* eslint-disable */

const title = t("Gösterge Paneli");
const items = [
  {
    text: "Elektro Portal",
    href: "/",
  },
  {
    text: t("Gösterge Paneli"),
    active: true,
  },
];

const series = [44, 55, 41, 17, 15];
const chartOptions = {
  labels: ["Schneider", "Siemens", "Emas", "Makita", "Max Extra"],
  chart: {
    height: 333,
    type: "donut",
  },
  legend: {
    position: "bottom",
  },
  stroke: {
    show: false,
  },
  dataLabels: {
    dropShadow: {
      enabled: false,
    },
  },
};
</script>
    
    <template>
  <Layout layout="horizontal">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">
                    {{ $t("Gunaydin_name", { name: user.firstName }) }}
                  </h4>
                  <p class="text-muted mb-0">
                    {{ $t("Sana nasıl yardımcı olabiliriz?") }}
                  </p>
                </div>
                <div class="mt-3 mt-lg-0">
                  <form action="javascript:void(0);">
                    <div class="row g-3 mb-0 align-items-center">
                      <div class="col-sm-auto">
                        <div class="input-group">
                          <flat-pickr
                            v-model="date"
                            :config="config"
                            class="
                              form-control
                              border-0
                              dash-filter-picker
                              shadow
                            "
                          ></flat-pickr>

                          <div
                            class="
                              input-group-text
                              bg-primary
                              border-primary
                              text-white
                            "
                          >
                            <i class="ri-calendar-2-line"></i>
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-auto">
                        <button type="button" class="btn btn-soft-secondary">
                          <i class="ri-add-circle-line align-middle me-1"></i>
                          {{ $t("Teklif Oluştur") }}
                        </button>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        {{ $t("Toplam İstenen Teklif Tutarı") }}
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <h5 class="text-success fs-14 mb-0">
                        <i
                          class="ri-arrow-right-up-line fs-13 align-middle"
                        ></i>
                        +5.00 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="23000.87"
                          :duration="5000"
                          decimals="2"
                          suffix=" ₺"
                          decimal=","
                          separator="."
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">{{
                        $t("Detaylı İncele")
                      }}</a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-success rounded fs-3">
                        <i class="bx bx-dollar-circle text-success"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate bg-info">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-white-50 mb-0">
                        {{ $t("Siparişe Dönüşen Teklif Tutarı") }}
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <h5 class="text-warning fs-14 mb-0">
                        <i
                          class="ri-arrow-right-down-line fs-13 align-middle"
                        ></i>
                        -3.57 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4
                        class="fs-22 fw-semibold ff-secondary mb-4 text-white"
                      >
                        <count-to
                          class="counter-value"
                          :startVal="0"
                          :endVal="19850.87"
                          :duration="5000"
                          decimals="2"
                          suffix=" ₺"
                          decimal=","
                          separator="."
                        ></count-to>
                      </h4>
                      <a
                        href=""
                        class="text-decoration-underline text-white-50"
                        >{{ $t("Detaylı İncele") }}</a
                      >
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-light rounded fs-3">
                        <i class="bx bx-shopping-bag text-white"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        {{ $t("Sipariş Sayısı") }}
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <h5 class="text-success fs-14 mb-0">
                        <i
                          class="ri-arrow-right-up-line fs-13 align-middle"
                        ></i>
                        +29.08 %
                      </h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="8"
                          :duration="5000"
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">{{
                        $t("Detaylı İncele")
                      }}</a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-warning rounded fs-3">
                        <i class="bx bx-user-circle text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-muted mb-0">
                        {{ $t("Bakiye") }}
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <h5 class="text-muted fs-14 mb-0">+0.00 %</h5>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-end justify-content-between mt-4"
                  >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to
                          :startVal="0"
                          :endVal="-4500.5"
                          :duration="5000"
                          decimals="2"
                          suffix=" ₺"
                          decimal=","
                          separator="."
                        ></count-to>
                      </h4>
                      <a href="" class="text-decoration-underline">{{
                        $t("Ödeme Bildir")
                      }}</a>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <div class="row">
            <div class="col-xl-8">
              <GenelBakis />
            </div>
            <!-- end col -->

            <div class="col-xl-4"><sonAktiviteler /></div>
            <!-- end col -->
          </div>

          <div class="row">
            <div class="col-xl-6">
              <div class="card card-height-100">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    {{ $t("Döviz Piyasası") }}
                  </h4>
                  <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown">
                      <a
                        class="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="text-muted"
                          >Report<i class="mdi mdi-chevron-down ms-1"></i
                        ></span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="#">Download Report</a>
                        <a class="dropdown-item" href="#">Export</a>
                        <a class="dropdown-item" href="#">Import</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="
                        table table-centered table-hover
                        align-middle
                        table-nowrap
                        mb-0
                      "
                    >
                      <tbody>
                        <tr v-for="i in 5">
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img src="" alt="" class="avatar-sm p-2" />
                              </div>
                              <div>
                                <h5 class="fs-14 my-1 fw-medium">
                                  <router-link
                                    to="/ecommerce/seller-details"
                                    class="text-reset"
                                    >EUR/TRY
                                  </router-link>
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="mb-0">18,31</p>
                            <span class="text-muted">{{ $t("Son") }}</span>
                          </td>
                          <td>
                            <p class="mb-0">17,90</p>
                            <span class="text-muted">{{ $t("Açılış") }}</span>
                          </td>
                          <td>
                            <p class="mb-0">18,32</p>
                            <span class="text-muted">{{
                              $t("En yüksek")
                            }}</span>
                          </td>
                          <td>
                            <p class="mb-0">17,93</p>
                            <span class="text-muted">{{ $t("En düşük") }}</span>
                          </td>
                          <td>
                            <h5 class="fs-14 mb-0">
                              +0,3967
                              <i
                                class="
                                  ri-arrow-right-up-line
                                  fs-16
                                  align-middle
                                  text-success
                                "
                              ></i>
                            </h5>
                            <span class="text-muted">{{ $t("Fark") }}</span>
                          </td>
                        </tr>
                        <!-- end -->
                      </tbody>
                    </table>
                    <!-- end table -->
                  </div>

                  <div
                    class="
                      align-items-center
                      mt-4
                      pt-2
                      justify-content-between
                      d-flex
                    "
                  >
                    <div class="flex-shrink-0">
                      <div class="text-muted">
                        {{ $t("son_guncelleme_time", { time: "14:53:00" }) }}
                      </div>
                    </div>
                    <ul
                      class="pagination pagination-separated pagination-sm mb-0"
                    >
                      <li class="page-item disabled">
                        <a href="#" class="page-link">←</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">1</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">2</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">3</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">→</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- .card-body-->
              </div>
              <!-- .card-->
            </div>

            <div class="col-xl-6">
              <div class="card card-height-100">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    {{ $t("Metal Piyasası") }}
                  </h4>
                  <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown">
                      <a
                        class="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="text-muted"
                          >Report<i class="mdi mdi-chevron-down ms-1"></i
                        ></span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="#">Download Report</a>
                        <a class="dropdown-item" href="#">Export</a>
                        <a class="dropdown-item" href="#">Import</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="
                        table table-centered table-hover
                        align-middle
                        table-nowrap
                        mb-0
                      "
                    >
                      <tbody>
                        <tr v-for="i in 5">
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img src="" alt="" class="avatar-sm p-2" />
                              </div>
                              <div>
                                <h5 class="fs-14 my-1 fw-medium">
                                  <router-link
                                    to="/ecommerce/seller-details"
                                    class="text-reset"
                                    >Bakır
                                  </router-link>
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="mb-0">18,31</p>
                            <span class="text-muted">{{ $t("Son") }}</span>
                          </td>
                          <td>
                            <p class="mb-0">17,90</p>
                            <span class="text-muted">{{ $t("Açılış") }}</span>
                          </td>
                          <td>
                            <p class="mb-0">18,32</p>
                            <span class="text-muted">{{
                              $t("En yüksek")
                            }}</span>
                          </td>
                          <td>
                            <p class="mb-0">17,93</p>
                            <span class="text-muted">{{ $t("En düşük") }}</span>
                          </td>
                          <td>
                            <h5 class="fs-14 mb-0">
                              +0,3967
                              <i
                                class="
                                  ri-arrow-right-up-line
                                  fs-16
                                  align-middle
                                  text-success
                                "
                              ></i>
                            </h5>
                            <span class="text-muted">{{ $t("Fark") }}</span>
                          </td>
                        </tr>
                        <!-- end -->
                      </tbody>
                    </table>
                    <!-- end table -->
                  </div>

                  <div
                    class="
                      align-items-center
                      mt-4
                      pt-2
                      justify-content-between
                      d-flex
                    "
                  >
                    <div class="flex-shrink-0">
                      <div class="text-muted">
                        {{ $t("son_guncelleme_time", { time: "14:53:00" }) }}
                      </div>
                    </div>
                    <ul
                      class="pagination pagination-separated pagination-sm mb-0"
                    >
                      <li class="page-item disabled">
                        <a href="#" class="page-link">←</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">1</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">2</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">3</a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">→</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- .card-body-->
              </div>
              <!-- .card-->
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4">
              <div class="card card-height-100">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    {{ $t("En Çok Tercih Ettiğiniz Markalar") }}
                  </h4>
                  <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown">
                      <a
                        class="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="text-muted"
                          >Report<i class="mdi mdi-chevron-down ms-1"></i
                        ></span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="#">Download Report</a>
                        <a class="dropdown-item" href="#">Export</a>
                        <a class="dropdown-item" href="#">Import</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <apexchart
                    class="apex-charts"
                    dir="ltr"
                    :series="series"
                    :options="chartOptions"
                  ></apexchart>
                </div>
              </div>
              <!-- .card-->
            </div>
            <!-- .col-->

            <div class="col-xl-8">
              <div class="card">
                <div class="card-header align-items-center d-flex">
                  <h4 class="card-title mb-0 flex-grow-1">
                    {{ $t("Son Teklifler") }}
                  </h4>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-soft-info btn-sm">
                      <i class="ri-file-list-3-line align-middle"></i>
                      {{ $t("Rapor Oluştur") }}
                    </button>
                  </div>
                </div>
                <!-- end card header -->

                <div class="card-body">
                  <div class="table-responsive table-card">
                    <table
                      class="
                        table table-borderless table-centered
                        align-middle
                        table-nowrap
                        mb-0
                      "
                    >
                      <thead class="text-muted table-light">
                        <tr>
                          <th scope="col">{{ $t("Teklif Numarası") }}</th>
                          <th scope="col">{{ $t("Müşteri Temsilcisi") }}</th>
                          <th scope="col">{{ $t("Tutar") }}</th>
                          <th scope="col">{{ $t("Markalar") }}</th>
                          <th scope="col">{{ $t("Durum") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2112
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-1.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle"
                                />
                              </div>
                              <div class="flex-grow-1">Alex Smith</div>
                            </div>
                          </td>
                          <td>Schneider, Siemens</td>
                          <td>
                            <span class="text-success">109.00 ₺</span>
                          </td>
                          <td>
                            <span class="badge badge-soft-success">{{
                              $t("Tamamlandı")
                            }}</span>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2111
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-2.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle"
                                />
                              </div>
                              <div class="flex-grow-1">Jansh Brown</div>
                            </div>
                          </td>
                          <td>Öznur Kablo</td>
                          <td>
                            <span class="text-success">149.00 ₺</span>
                          </td>
                          <td>
                            <span class="badge badge-soft-warning">{{
                              $t("Süreç Devam Ediyor")
                            }}</span>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2109
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-3.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle"
                                />
                              </div>
                              <div class="flex-grow-1">Ayaan Bowen</div>
                            </div>
                          </td>
                          <td>Emas, Entes</td>
                          <td>
                            <span class="text-success">215.00 ₺</span>
                          </td>
                          <td>
                            <span class="badge badge-soft-success">{{
                              $t("Tamamlandı")
                            }}</span>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2108
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-4.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle"
                                />
                              </div>
                              <div class="flex-grow-1">Prezy Mark</div>
                            </div>
                          </td>
                          <td>Makita, Max Extra, Biglift</td>
                          <td>
                            <span class="text-success">199.00 ₺</span>
                          </td>
                          <td>
                            <span class="badge badge-soft-danger">{{
                              $t("İptal Edildi")
                            }}</span>
                          </td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <td>
                            <router-link
                              to="/ecommerce/order-details"
                              class="fw-medium link-primary"
                              >#VZ2107
                            </router-link>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-2">
                                <img
                                  src="@/assets/images/users/avatar-6.jpg"
                                  alt=""
                                  class="avatar-xs rounded-circle"
                                />
                              </div>
                              <div class="flex-grow-1">Vihan Hudda</div>
                            </div>
                          </td>
                          <td>Global</td>
                          <td>
                            <span class="text-success">330.00 ₺</span>
                          </td>
                          <td>
                            <span class="badge badge-soft-success">{{
                              $t("Tamamlandı")
                            }}</span>
                          </td>
                        </tr>
                        <!-- end tr -->
                      </tbody>
                      <!-- end tbody -->
                    </table>
                    <!-- end table -->
                  </div>
                </div>
              </div>
              <!-- .card-->
            </div>
            <!-- .col-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>